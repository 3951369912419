@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  #PaymentMethodsPageTab {
    display: none !important;
  }


  /* Font */
  composes: textSmall from global;

  color: var(--colorGrayText);
  padding-bottom: 10px;
  padding-top: 10px;
  height: 100%;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  &:hover {
    text-decoration: none;
    color: var(--colorBlack);
  }

  @media (--viewportLarge) {
    font-weight: var(--fontWeightMedium);
    font-size: var(--fontSizeRegular);
    line-height: 18px;
    border: 1px solid var(--colorPrimary);
    border-radius: 8px;
    padding: 16px;

    height: unset;
    width: var(--TabNav_linkWidth);
    margin-top: 0;
    margin-bottom: 16px;

    &:hover {
      background-color: var(--colorBorder);
    }
  }

  @media (max-width: 1023px) {
    border: 1px solid var(--colorGrey100);
    padding: 15px;
    border-radius: 4px;
  }

  @media (max-width: 425px) {
    padding: 10px;
  }
}

.selectedLink {
  border-bottom-color: var(--colorBlack);
  color: var(--colorPrimary);
  
  @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
    background: var(--colorPrimary);
    border: 1px solid var(--colorPrimary);
    border-radius: 8px;
    padding: 16px;
    color: var(--colorWhite);

    &:hover {
      background-color: var(--colorBorder);
    }
  }

  @media (max-width: 1023px) {
    border-bottom-width: 3px;
  }
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
  border: 1px solid var(--colorGrey300);
  border-radius: 8px;
  padding: 16px;
}
