.root {
  height: 100%;
  width: 100%;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
}

.clickedWithMouse {
  &:focus {
    outline: none;
  }
}

/* Default isOpen class - no styles yet */
.isOpen {
}
