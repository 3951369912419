.root {
  @media (max-width: 425px) {
    width: 100%;
  }
}

.iconBath svg {
  fill: none;

}
