.root {
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  background-color: var(--colorWhite);
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: grid;
  padding: 0 24px;
  background-color: var(--colorWhite);
  
  @media (--viewportSmall) {
    padding: 0 32px 80px 32px;
  }
}
