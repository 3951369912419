.root {
  width: 100%;
  height: 100%;
  background-color: var(--colorGrey100);
  border-radius: 16px;
}

.mapRoot {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
