@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  align-items: center;
  height: 60px;
  max-width: 370px;
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
    width: 173px !important;
  }
}

.logo {
  /* If height is increased, change link margins accordingly */
  /* NOTE: extremely wide logos may look bad. This will scale them to container of 24 x 200. */
  height: auto;
  width: auto;
  /* Mobile topbar has maximum of 100vw - 132px space for logo */
  max-width: min(calc(100vw - 132px), 100%, 370px);
  object-fit: contain;
  object-position: center;

  @media (--viewportLarge) {
    max-width: min(100%, 370px);
  }
}

.logo24 {
  max-height: 24px;
}
.logo36 {
  max-height: 28px;
  width: 110px;
  
  @media (--viewportMedium) {
    max-height: 51px;
    width: 173px;
  }
}
.logo48 {
  max-height: 48px;
  
  @media (max-width: 425px) {
    max-height: 35px;
  }

  @media (max-width: 349px) {
    max-height: 32px;
  }
}
